import en from '../en/exhibitions'

const cz: typeof en = {
  exhibitions: 'Výstavy',
  public_exhibitions: 'Veřejné výstavy',
  my_exhibitions: 'Mé výstavy',
  exhibitions_shown: 'Zobrazeno {{count}} výstav',
  add_exhibition: 'Přidat výstavu',
  new_exhibition: 'Nová výstava',
  how_to_use_exhibitions: 'Jak využívat nástroj výstavy?',
  discard_changes: 'Zahodit změny',
  save_and_display: 'Uložit a zobrazit',
  save_exhibtion: 'Uložit výstavu',
  edit_exhibition: 'Upravit výstavu',
  delete_exhibition: 'Odstranit výstavu',
  name_of_exhibition: 'Název výstavy',
  type_of_exhibition_view: 'Druh vzhledu výstavy',
  album: 'Album',
  storyline: 'Storyline',
  slider: 'Slider',
  add_illustration: 'Přidat ilustraci',
  illustrations: 'ilustrací',
  search_illustration: 'Vyhledat ilustraci',
  search_placeholder:
    'Začněte psát název uměleckého díla, abyste jej rychle přidali do své výstavy',
  from_selection: 'Z mého výběru',
  add_description: 'Přidat popis',
  unsaved_changes: 'Vaše změny nebyly uloženy již {{count}} minut',
  did_not_found: 'Nenašli jste, co jste hledali?',
  go_to_artwork_search:
    'Přejděte na vyhledávání uměleckých děl a prozkoumejte umělce',
  objects_and_more: ', předměty a další.',
  dont_forget_to_save: 'Nezapomeňte si uložit svou ilustraci',
  add_from_selection: 'Přidání ilustrace z mého výběru',
  empty_selection: 'Váš výběr je prázdný.',
  add_to_exhibition: 'Přidat do výstavy',
  headline: 'Nadpis (nepovinné)',
  year: 'Rok (nepovinné)',
  custom_title: 'Přidejte název pro zobrazení příběhu a ukázky',
  custom_date: 'Přidejte vlastní datum pro zobrazení příběhu',
  published: 'Publikováno',
  private: 'Soukromé',
  featured_artists: 'Umělci uvedeni v této výstavě: ',
  artworks: 'umělecká díla',
  explore_artists: 'Prozkoumejte umělce v této výstavě: ',
  remove_from_exhibition: 'Odebrat z výstavy',
  year_title: 'rok',
  saving_exhibition: 'Probíhá ukládání výstavy',
  exhibition_saved_successfully: 'Uložení výstavy proběhlo úspěšně',
  error_when_saving_exhibition: 'Při ukládání výstavy nastala chyba',
  exhibition_not_found: 'Výstava nebyla nalezena',
  exhibition_deleted: 'Výstava byla smazána',
  deletion_in_progress: 'Probíhá mazání výstavy',
  deletion_error: 'Nastala chyba při mazání výstavy',
  publicity_changing: 'Probíhá změna publicity',
  publicity_changed: 'Změna publikace proběhla úspěšně',
  publicity_change_error: 'Nastala chyba při změně publikace',
  go_to_complex_search: 'Přejděte na pokročilé vyhledávání',
  and_use_my_selection: ' a použijte funkci Můj výběr.',
  in_book: 'V knize: ',
  author: 'Autor: ',
  user_name: 'Autor výstavy: ',
  featured_books: 'Použité knihy: ',
  created: 'Vytvořeno: ',
  inactivity_modal_header: 'Upozornění',
  inactivity_modal_text: 'Vaše změny nejsou uloženy přes 15 minut',
  inactivity_modal_button: 'Zavřít',
  default_illustration: 'Výchozí ilustrace',
  set_default_illustration: 'Je nutno nastavit výchozí ilustraci',
}

export default cz
