export default {
  keywords: 'Keywords',
  go_to_create: 'Go to creating exhibitions',
  create_exhibition: 'Create exhibition',
  search_keyword: 'Search for keyword',
  themes: 'Themes',
  characters: 'Characters',
  animals: 'Animals',
  plants: 'Plants',
  objects: 'Objects',
  bible: 'Bible',
  heraldry: 'Heraldry',
  architecture: 'Architecture',
  locations: 'Locations',
  military: 'Military',
}
