export default {
  1: 'Náboženství a magie',
  10: '(symbolická) zobrazení ~ stvoření, kosmos, vesmír a život (v nejširším slova smyslu)',
  11: 'křesťanské náboženství',
  12: 'nekřesťanská náboženství (včetně institucí, zvyků a památek)',
  13: 'magie, víra v nadpřirozeno, okultismus',
  14: 'astrologie',
  2: 'Příroda',
  20: '"Natura" (alegorická postava nebo výjev; Diana z Efesu); "Natura" (Ripa)',
  21: 'čtyři živly (oheň, vzduch, voda, země) a éter jako pátý živel (prvek)',
  22: 'přírodní jevy',
  23: 'čas',
  24: 'nebe (nebeská tělesa)',
  25: 'země, svět jako vesmírné těleso',
  26: 'meteorologické jevy',
  29: 'surrealistická zobrazení',
  3: 'Lidská bytost, člověk obecně',
  31: 'člověk v obecném biologickém smyslu',
  32: 'lidské typy; národy a národnosti',
  33: 'vztahy mezi jednotlivými osobami',
  34: 'člověk a zvíře',
  35: 'venkovské scenérie, bukolika',
  36: 'člověk a životní prostředí',
  4: 'Společnost, civilizace, kultura',
  41: 'materiální aspekty každodenního života',
  42: 'rodina, rodinný život, potomstvo',
  43: 'odpočinek, zábava',
  44: 'stát; právo; politický život',
  45: 'válčení; vojenské záležitosti',
  46: 'společenský a hospodářský život, doprava a komunikace',
  47: 'řemesla a výroba; průmysl',
  48: 'umění',
  49: 'výchova, věda a vzdělávání',
  5: 'Abstraktní myšlenky a koncepty',
  51: 'obecné koncepty (obecnosti)',
  52: 'rozumové uvažování a tvorba myšlenek',
  53: 'vůle, chtění',
  54: 'jednání',
  55: 'vlastnictví',
  56: 'emoce',
  57: 'morálka a mravnost',
  58: '(s)tvoření, plodnost',
  59: 'aspekty sociálního chování',
  6: 'Historie',
  61: 'historické události a dějinné situace; historické osobnosti',
  62: 'představy o budoucnosti',
  7: 'Bible',
  71: 'Starý zákon',
  72: 'typologické předobrazy; typologické kompozice',
  73: 'Nový zákon',
  8: 'Literatura',
  81: 'literární cykly',
  82: 'literární postavy a předměty',
  83: 'konkrétní literární díla',
  84: 'příběhy a pohádky',
  85: 'bajky',
  86: 'přísloví, rčení apod.',
  9: 'Mytologie, antika (starověk)',
  91: 'Mýty o stvoření: kosmogonie, teogonie a původ člověka',
  92: 'bohové ~ klasická mytologie',
  93: 'slavnosti, shromáždění a sídla bohů',
  94: 'řecké báje a pověsti (I)',
  95: 'řecké báje a pověsti (II)',
  96: 'římští bohové, římské báje a pověsti',
  97: 'metamorfózy ~ klasická mytologie',
  98: 'dějiny starověku',
}
