export default {
  filter_author: 'Author',
  filter_year: 'Year',
  filter_place: 'Place',
  filter_object: 'Object',
  filter_iiif_format: 'Available in IIIF',
  category: 'Category',
  vise: 'Vise',
  sort: 'Sort',
  delete_all: 'Delete all',
}
