export default {
  search_illustration: 'Search illustration',
  go_to_iconclass: 'Go to ICONCLASS',
  themes: 'Themes',
  deselect_all: 'Deselect all',
  filter: 'Filter',
  title: 'Name',
  icc_code: 'ICONCLASS code',
  icc_name: 'ICONCLASS term',
  identifier: 'Record ID',
}
