export default {
  header: 'Search',
  filter: 'Fitler',
  filter_results: 'Filtering of results',
  no_options: 'No options',
  year: 'Year',
  author: 'Who',
  author_search: 'Search person',
  place: 'Where',
  place_search: 'Search place',
  publishing_place_search: 'Search publishing place',
  what: 'What',
  object_search: 'Search',
  iiif_format: 'Available in IIIF format',
  books: 'Books',
  illustrations: 'Illustrations',
  book_name: 'Name (Book) A-Z',
  category: 'Select category',
  search: 'Search',
  cancel_search: 'Cancel search',
  search_expression: 'Search for an expression...',
  records_count: 'Records count: ',
  record_id: 'Record ID',
  number_of_illustrations_in_publication:
    'number of illustrations in the publication',
  from: 'From',
  to: 'To',
  all: 'All',
  main_author: 'Author',
  title: 'Title',
  publishing_place: 'Publishing place',
  printer_publisher: 'Printer/publisher',
  publishing_year: 'Year',
  delete: 'Delete',
  add: 'Add next',
  oldest: 'Oldest',
  newest: 'Newest',
  person: 'depicted',
  keyword: 'secondary motive',
  theme: 'main motive',
}
